import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../layout';
import Bio from '../components/Bio';
import PostTags from '../components/PostTags';
import SocialLinks from '../components/SocialLinks';
import SEO from '../components/SEO';
import config from '../../data/SiteConfig';
import { postMeta, pagination, toc } from './post.module.scss';
import '../../prism/prism.css';
import '../../prism/prismAdjust.css';
import '../../prism/gatsby-remark-code-title.css';
import 'katex/dist/katex.min.css';
import { f } from '../lib/date';

export default ({ data, pageContext }) => {
  const { slug, nexttitle, nextslug, prevtitle, prevslug } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;
  const date = postNode.fields.date;
  const tableOfContents = postNode.tableOfContents;
  if (!post.id) {
    post.id = slug;
  }
  return (
    <Layout>
      <main>
        <Helmet>
          <title>{`${post.title} | ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postPath={slug} postNode={postNode} postSEO />
        <div>
          <h1>{post.title}</h1>
          <p className={postMeta}>
            {f(date)} &mdash; この記事は{postNode.timeToRead}分で読めます{' '}
          </p>
          <div className={postMeta}>
            <PostTags tags={post.tags} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: postNode.html }} />

          <hr />
          <Bio config={config} />
        </div>
        <nav>
          <ul className={pagination}>
            <li>
              <Link to={prevslug} rel="prev">
                ← {prevtitle}
              </Link>
            </li>
            <li>
              <Link to={nextslug} rel="next">
                {nexttitle}→
              </Link>
            </li>
          </ul>
        </nav>
      </main>
      <aside>
        <div
          className={toc}
          dangerouslySetInnerHTML={{ __html: tableOfContents }}
        />
      </aside>
    </Layout>
  );
};

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      tableOfContents(
        absolute: false
        pathToSlugField: "frontmatter.path"
        maxDepth: 3
      )
      frontmatter {
        title
        cover
        date
        categories
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;
